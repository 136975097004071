import React, {useMemo, useState} from "react";
import {Button, Modal, Space, Upload, UploadFile} from "antd";
import Page from "components/Molecules/page";
import Tabs from "components/Organisms/tabs";
import {useNavigate} from "react-router-dom";
import {ReviewTable} from "../../components/ReviewTable";
import styled from "@emotion/styled";
import {useFetchReviewerOverview, useImportExcel, useImportTrackerAndFiles, useListReview} from "pages/Reviews/api";
import {withFiltersOptions} from "HOCs/withFiltersOptions";
import omit from "lodash/omit";
import {MainLayout} from "components/Organisms/layouts";
import Statistics from "./components/Statistics";
import {useAuth} from "context/auth-context";
import {Role} from "configs/rules";
import {useHttpDownload} from "../../../../utils/http";
import {downloadFile} from "../../../../utils/file";
import {UploadOutlined} from "@ant-design/icons";
import {File} from "../../../../components/Organisms/uploads/upload-doc";

const FullWidth = styled(Space)`
    width: 100%;
`;

const StyledLayout = styled(Page)`
    margin: auto;
    padding-top: 24px;
`;

const Home = withFiltersOptions(({filters, role}: any) => {
  const navigate = useNavigate();
  const overview = useFetchReviewerOverview();
  const {user} = useAuth();
  const client = useHttpDownload();
  const {mutate: upload, isLoading: importLoading} = useImportExcel()
  const {mutate: uploadTracker, isLoading: uploadTrackerLoading} = useImportTrackerAndFiles()
  const [keywords, setKeywords] = useState<string>()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [waitingForReviews, onChangeTask, ...data] = useListReview([
    "waiting_for_review",
    "rejected_quote_accepted",
  ] as any, keywords);

  const [allTask, onChangeAllTask] = useListReview([
    "waiting_for_review",
    "completed",
    "rejected_quote_accepted",
    "rejected_request_additional_test",
    "rejected_request_revise",
    "rejected_others",
  ] as any, keywords);

  const showModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleOk = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      // @ts-ignore
      formData.append("files", file);
    });

    try {
      const res: File = await uploadTracker(formData);
    } catch (err) {
    }
    navigate(0)
  };

  const handleCancel = () => {
    setFileList([])
    setIsUploadModalOpen(false);
  };

  const onChange = (
    pagination: { current: number; pageSize: number },
    filtered: Record<string, any>,
    sorted: Record<string, any>,
    extra: { action: string; currentDataSource: Array<any> }
  ) => {
    onChangeTask(
      pagination,
      {
        ...filtered,
        status: ["waiting_for_review", "rejected_quote_accepted"],
      },
      sorted,
      extra
    );
  };
  const onChangeJob = (
    pagination: { current: number; pageSize: number },
    filtered: Record<string, any>,
    sorted: Record<string, any>,
    extra: { action: string; currentDataSource: Array<any> }
  ) => {

    onChangeAllTask(
      pagination,
      {
        ...filtered,
        status:
          Array.isArray(filtered.status) && filtered.status.length
            ? filtered.status
            : null,
      },
      sorted,
      extra
    );
  };

  const handleOnSearch = async (value) => {
    setKeywords(value)
  };

  const onDownload = async () => {
    const data = await client(
      `products/export`
    );

    downloadFile(data, 'wm_export.xlsx')
  };

  const onDownloadAllTask = async () => {
    setIsDownloading(true)
    const data = await client(
      `products/export/task`
    );

    setIsDownloading(false)
    downloadFile(data, 'wm_export.xlsx')
  };

  const onDownloadLatestTask = async () => {
    setIsDownloading(true)
    const data = await client(
      `products/export/latest_task`
    );
    setIsDownloading(false)
    downloadFile(data, 'wm_export.xlsx')
  };


  const cusUpload = async (options: any) => {
    const {onSuccess, onError, file} = options;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const res: File = await upload(formData);
      onSuccess(res);
    } catch (err) {
      onError({err});
    }
  };

  const cusTrackersUpload = async (options: any) => {
    console.log(options)
    const {onSuccess, onError, file} = options;

    const tempFileList = fileList || []
    tempFileList.push(file)

    // console.log()
    setFileList(tempFileList)
    onSuccess(file)

    // const formData = new FormData();
    // formData.append("files", file);
    //
    // try {
    //   const res: File = await uploadTracker(formData);
    //   onSuccess(res);
    // } catch (err) {
    //   onError({err});
    // }
  };

  const docProps = {
    customRequest: cusUpload,
    multiple: false,
    name: "file",
    showUploadList: false
  };

  const trackerProps = {
    customRequest: cusTrackersUpload,
    multiple: true,
    name: "files",
    // showUploadList: true,
    // preview
  };

  const uploadProps = useMemo(
    () => ({
      beforeUpload: (file: UploadFile) => {

        setFileList((state) => [...state, file]);
        return false;
      },
      onRemove: (file: UploadFile) => {
        if (fileList.some((item) => item.uid === file.uid)) {
          setFileList((fileList) =>
            fileList.filter((item) => item.uid !== file.uid)
          );
          return true;
        }
        return false;
      }
    }),
    [fileList]
  );

  return (
    <>
      <MainLayout>
        {/* <CusPageHeader title="Home"></CusPageHeader> */}
        <StyledLayout>
          <FullWidth direction="vertical" size={32}>
            <Statistics
              navigate={navigate}
              overview={overview?.data || {}}
            ></Statistics>
            <Space direction="vertical" size={[0, 12]}>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <div>
                  <h2>My Jobs</h2>
                </div>
                <div>
                  <Button loading={importLoading} onClick={onDownload} style={{marginLeft: "20px"}}>Download</Button>
                </div>

                <div>
                  {/*<div style={{marginLeft: '5px'}}>*/}
                  {/*  <Upload {...docProps}>*/}
                  {/*    <Button icon={<UploadOutlined/>}>*/}
                  {/*      Import Excel*/}
                  {/*    </Button>*/}
                  {/*  </Upload>*/}
                  {/*</div>*/}
                  {/*<div style={{marginLeft: '5px'}}>*/}
                  {/*  <Button loading={isDownloading} onClick={onDownloadAllTask} style={{marginLeft: "20px"}}>Download All*/}
                  {/*    Task</Button>*/}
                </div>
                <div style={{marginLeft: '5px'}}>
                  <Button icon={<UploadOutlined/>} onClick={showModal}>
                    Import Tracker & files
                  </Button>
                </div>
                {/*<div style={{marginLeft: '5px'}}>*/}
                {/*  <Button loading={isDownloading} onClick={onDownloadAllTask} style={{marginLeft: "20px"}}>Download All*/}
                {/*    Task</Button>*/}

                {/*</div>*/}

                {/*<div style={{marginLeft: '5px'}}>*/}
                {/*  <Button loading={isDownloading} onClick={onDownloadLatestTask} style={{marginLeft: "20px"}}>Download*/}
                {/*    Latest*/}
                {/*    Task</Button>*/}

                {/*</div>*/}
              </div>
            </Space>
            <Tabs
              tabs={[
                {
                  key: "todo",
                  tab: "Todo",
                  content: (
                    <ReviewTable
                      scroll={{x: true}}
                      loading={waitingForReviews.isLoading}
                      onSearch={handleOnSearch}
                      dataSource={(waitingForReviews as any)?.data?.list ?? []}
                      defaultFilteredValue={{
                        status: [
                          "waiting_for_review",
                          "rejected_quote_accepted",
                        ],
                      }}
                      filters={omit(filters, "status")}
                      onChange={onChange}
                      pagination={{
                        total: (waitingForReviews as any)?.data?.total ?? 0,
                      }}
                    ></ReviewTable>
                  ),
                },
                {
                  key: "myJobs",
                  tab: "All jobs",
                  content: (
                    <ReviewTable
                      scroll={{x: true}}
                      dataSource={allTask?.data?.list ?? []}
                      filters={filters}
                      loading={allTask.isLoading}
                      onSearch={handleOnSearch}
                      onChange={onChangeJob}
                      defaultFilteredValue={{
                        status: [
                          "waiting_for_review",
                          "completed",
                          "rejected_quote_accepted",
                          "rejected_request_additional_test",
                          "rejected_request_revise",
                          "rejected_others",
                        ].concat(user.userRole === Role.BUYER ? [
                          "missing_documents",
                          "pending_payment"
                        ] : []),
                      }}
                      pagination={{
                        // current: 1,
                        current: (allTask as any)?.data?.page ?? 1,
                        total: (allTask as any)?.data?.total ?? 0,
                      }}
                    ></ReviewTable>
                  ),
                },
              ].filter(({key}) => {
                if (user.userRole === Role.BUYER && key === "todo")
                  return false;
                return true;
              })}
            ></Tabs>
          </FullWidth>
        </StyledLayout>
        <Modal title="Upload Tracker" visible={isUploadModalOpen} onOk={handleOk} onCancel={handleCancel} okText={"Submit"} confirmLoading={uploadTrackerLoading}>
          <div style={{marginLeft: '5px'}}>
            <Upload {...uploadProps} multiple fileList={fileList}>
              <Button icon={<UploadOutlined/>}>
                Upload all files
              </Button>
            </Upload>
          </div>
        </Modal>
      </MainLayout>
    </>
  );
});

export default Home;
